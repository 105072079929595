.case-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #77beb3;
  border-radius: var(--sp);
  height: calc(14 * var(--sp));
  width: calc(14 * var(--sp));
  /* padding-top: var(--sp); */
  cursor: pointer;
}

.case-card-image {
  height: 60%;
  max-height: 60%;
  overflow: hidden;
  /* margin-top: calc(1.07019375 * var(--sp)); */
  /* padding: calc(1.07019375 * var(--sp)) calc(1.07019375 * var(--sp)); */
}

.case-card-image > img {
  /*scale:1.5;*/
  object-fit: contain;
  width: 100%;
  max-height: 100%;
}

.case-card-text-container {
  display: flex;
  padding: calc(0.535096875 * var(--sp));
  height: 40%;
  /* border-radius: var(--sp); */
  background-color: #dde0e3;
  justify-content: center;
  align-items: center;
}
