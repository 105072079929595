* {
  margin: 0;
  color: var(--black);
}

:root {
  --brand-blue: #0757a5;
  --brand-light-blue: #42b3ea;
  --brand-light-green: #84d427;
  --primary-backgound: #00687f;
  --primary: #25a186;
  --primary-dark: #22937b;
  --secondary: #435d83;
  --background-blue: #1f688c;
  --card-background: #77beb3;
  --light-green: #acdcce;
  --card-gray: #dde0e3;
  --active-step: #ff8040;
  /*--highEmphasisBlackText:#000000DE;*/
  --black: #000000de;
  --white: #ffffffde;
  --mediumEmphasisBlackText: #0000008a;
  --disabledBlackText: #ffffff61;
  --highEmphasisWhiteText: #ffffffde;
  --medium-emphasis-white-text: #ffffff8a;
  --stoke-blue: #007acd;

  --sp: min(1.5625vw, 2.77vh);
}

.gc-container {
  width: calc(64 * var(--sp));
  height: calc(36 * var(--sp));
  margin-left: auto;
  margin-right: auto;
}

.bg-active {
  background-color: var(--active-step);
}

#root,
body,
.fullscreen {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-circle {
  border: none;
  cursor: pointer;
  border-radius: 50%;
  background-color: #fff;
  padding: calc(0.499 * var(--sp));
}

.p-2 {
  padding: var(--sp) !important;
}

.p-1 {
  padding: calc(0.285385 * var(--sp));
}

/*MenuScreenStyles*/

.menu-container {
  padding-right: calc(2.1403875 * var(--sp));
  padding-left: calc(2.1403875 * var(--sp));
  padding-bottom: calc(1.605290625 * var(--sp));
  padding-top: calc(1.605290625 * var(--sp));
}

input {
  background-color: #d8d8d8;
  padding: calc(0.4280775 * var(--sp));
  color: #717171;
  font-weight: bold;
  border-radius: 0;
  border: 0;
}

.gamecase-bg-default {
  background-image: url(background-blue.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

/*AuthenticationLayout*/

.auth-panel-wrapper {
  width: calc(10.7019375 * var(--sp));
  padding: calc(0.891828125 * var(--sp));
  box-sizing: content-box;
  background-color: white;
}

.auth-panel-wrapperimg {
  width: calc(8.91828125 * var(--sp));
}

@media (min-width: 480) {
  .auth-panel-wrapper {
    width: calc(12.48559375 * var(--sp));
    padding: calc(1.426925 * var(--sp));
  }
}

.rounded {
  border-radius: calc(0.107019375 * var(--sp)) !important;
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.h-100 {
  height: 100%;
}

.h-75 {
  height: 75%;
}

.mb-2 {
  margin-bottom: var(--sp);
}

.ml-2 {
  margin-left: var(--sp);
}

.mr-2 {
  margin-right: var(--sp);
}

/*MenuScreen*/

.menu-container {
  padding: calc(2 * var(--sp)) calc(3 * var(--sp));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.spaced-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.gray-box {
  display: flex;
  padding: var(--sp);
  border-radius: var(--sp);
  align-items: center;
  width: calc(21 * var(--sp));
  height: calc(4 * var(--sp));
  background-color: var(--card-gray);
}

.bg-gray {
  background-color: var(--card-gray);
}

.bg-blue {
  background-color: var(--background-blue);
}

.pull-right {
  float: right;
}

.menu-container .avatar {
  height: calc(14 * var(--sp));
  width: calc(14 * var(--sp));
  border-radius: var(--sp);
  cursor: pointer;
  background-color: #cad2d8;
}

.cursor-pointer {
  cursor: pointer;
}

.p-relative {
  position: relative;
}

.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.p-absolute {
  position: absolute;
}

.bottom-phases {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.player-message-box {
  position: absolute;
  bottom: 0;
  right: 0;
  left: calc(9 * var(--sp));
  height: calc(10 * var(--sp));
  padding: var(--sp) calc(1.427 * var(--sp));
  border-radius: calc(5 * var(--sp));
}

.player-box {
  position: absolute;
  bottom: 16%;
  margin: 0 calc(1.07 * var(--sp));
  left: 0;
  right: 0;
}

.player-box > div {
  position: relative;
  /*height:100%;*/
  display: flex;
  align-items: flex-end;
}

.btn-svg {
  background: none;
  /*background-color:red;*/
  cursor: pointer;
  padding: 0;
  border: 0;
}

.speech {
  z-index: 0;
  background-color: var(--light-green);
  padding-left: calc(5 * var(--sp));
  color: var(--white);
}

.no-speech {
  z-index: 1;
  background-color: white;
  padding-left: 10vmin;
}

.player-box .avatar {
  height: calc(13 * var(--sp));
  width: calc(13 * var(--sp));
  border-radius: var(--sp);
  background-color: #cad2d8;
}

.base-shadow {
  -webkit-box-shadow: 0px 0px calc(0.2854 * var(--sp)) 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px calc(0.2854 * var(--sp)) 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px calc(0.2854 * var(--sp)) 0px rgba(0, 0, 0, 0.75);
}

.text-full-white * {
  color: white;
}

.text-white * {
  color: var(--white);
}

.text-white {
  color: var(--white);
}

.text-align-center {
  text-align: center;
}

.medical-record-panel {
  background-color: #a9e1de;
  border-radius: calc(1.14154 * var(--sp));
  padding: calc(1.71231 * var(--sp)) calc(1.71231 * var(--sp)) 0
    calc(1.71231 * var(--sp));
  width: 70%;
  min-width: 70%;
  max-width: 70%;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
}

.medical-record {
  display: flex;
  padding: calc(1.997695 * var(--sp)) !important;
}

/*DialogsStyles*/

.config-outside-container {
  width: 70%;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.config-container {
  background-color: #fff;
  position: relative;
  padding: calc(1.997695 * var(--sp)) 15%;
  border-radius: calc(1.997695 * var(--sp));
  /*padding-right:calc(8.56155*var(--sp));*/
  /*padding-left:calc(8.56155*var(--sp));*/
  /*padding-top:calc(1.07019375*var(--sp));*/
  /*padding-bottom:calc(1.07019375*var(--sp));*/
  /*width:80%;*/
  /*height:80%;*/
  /*margin-left:auto;
margin-right:auto;*/
}

.config-btn-inline {
  display: flex;
}

.config-close-btn {
  position: absolute;
  right: 30px;
  top: 30px;
}

.config-label-btn-row {
  display: flex;
  flex-direction: row;
  margin-bottom: calc(1.07019375 * var(--sp));
}

.config-grid {
  display: grid;
  grid-template-columns: 1fr3fr;
  margin-top: calc(1.07019375 * var(--sp));
}

.config-btn-grid {
  display: grid;
  grid-template-columns: 1fr1fr;
  gap: 30px;
}

.config-label-colum {
  width: calc(10.7019375 * var(--sp));
}

.config-btn {
  display: flex;
  flex-grow: 1;
  margin-bottom: calc(0.535096875 * var(--sp));
}

.alert-modal {
  display: flex;
  flex-direction: column;
  /* width: 75%;
  height: 70%; */
  width: calc(46.0896775 * var(--sp));
  height: calc(23.33022375 * var(--sp));
  border-radius: calc(1.997695 * var(--sp));
  padding: calc(6.6281 * var(--sp)) calc(9.942 * var(--sp));
  justify-content: space-between;
  position: relative;
  background-color: white;
}

.alert-btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: calc(2.1403875 * var(--sp));
  margin-left: var(--sp);
  margin-right: var(--sp);
}

.alert-btn {
  width: calc(9.63174375 * var(--sp));
}

.alert-close {
  position: absolute;
  right: calc(2 * var(--sp));
  top: calc(2 * var(--sp));
  cursor: pointer;
  background-color: transparent;
}

.feedback-box {
  position: absolute;
  right: 112px;
  bottom: calc(18 * var(--sp));
  padding: calc(0.856155 * var(--sp)) calc(0.856155 * var(--sp));
  border-radius: calc(1.426925 * var(--sp));
  min-height: calc(4.56616 * var(--sp));
  width: calc(21 * var(--sp));
  display: flex;
  align-items: center;
}

.bg-light-green {
  background-color: var(--light-green);
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.clear-both {
  clear: both;
}

.no-speech .checkbox {
  background-color: var(--light-green);
}

.checkbox {
  min-width: calc(3 * var(--sp));
  min-height: calc(2 * var(--sp));

  max-width: calc(3 * var(--sp));
  max-height: calc(2 * var(--sp));

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(0.285385 * var(--sp));
  margin-right: var(--sp);
  cursor: pointer;
  border: none;
  background-color: white;
}

.checkbox-icon {
  width: calc(0.8562 * var(--sp));
  height: calc(0.8562 * var(--sp));
}

.think-circles {
  height: calc(2 * var(--sp));
  position: absolute;
  right: calc(1 * var(--sp));
  top: calc(1 * var(--sp));
}

.score-bar {
  position: absolute;
  right: calc(2 * var(--sp));
  top: calc(2 * var(--sp));
  border-radius: calc(0.856155 * var(--sp));
  background-color: var(--card-gray);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-end;
  width: calc(2 * var(--sp));
  height: calc(16 * var(--sp));
  display: flex;
}

.bg-orange {
  background-color: var(--active-step);
}

.h-50 {
  height: 50%;
}

.h-60 {
  height: 60%;
}

.h-40 {
  height: 40%;
}

.svg-container {
  align-items: center;
  position: absolute;
  z-index: 1;
}

.banner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  object-fit: contain;
}

.bottom-text-container {
  position: absolute;
  padding: calc(0 * var(--sp)) calc(0 * var(--sp));
  width: 100vw;
  bottom: 10%;
}

.gc-history-img {
  height: calc(9.5 * var(--sp));
  width: calc(9.5 * var(--sp));
  margin-right: calc(2.5 * var(--sp));
}

.interference-screen-text {
  position: absolute;
  bottom: 50%;
  left: var(--sp);
  right: var(--sp);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ending-screen-btn {
  height: calc(4 * var(--sp));
  border-radius: calc(0.5 * var(--sp));
}

button:focus {
  outline: 0;
}

.mb-1b {
  margin-bottom: var(--sp);
}

.p-2b {
  padding: calc(2 * var(--sp));
}

/* Scroll configuration */
/* width */
::-webkit-scrollbar {
  width: 12px ;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 16px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d4d4d4;
  border-radius: 16px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b4b4b4;
}