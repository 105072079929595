.btn-container-style {
  display: flex;
  /* height: calc(2.1403875 * var(--sp)); */
  cursor: pointer;
  border-radius: calc(0.535096875 * var(--sp));
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.btn-size {
  height: calc(2.1403875 * var(--sp));
}

.btn-intern-style {
  padding: var(--sp);
  flex: 1;
}
.btn-disabled {
  cursor: default !important;
  background-color: #84d0c3 !important;
}
.btn-with-icon {
  padding-right: calc(1.14154 * var(--sp));
  padding-left: var(--sp);
  padding-top: var(--sp);
  padding-bottom: var(--sp);
  align-items: center;
}

.btn-step-container {
  align-items: stretch;
  flex-grow: 1;
}

.btn {
  display: flex;
  /*min-height:10vh;*/
  cursor: pointer;
  border: 0;
  padding: calc(0.856155 * var(--sp)) calc(1.14154 * var(--sp));
  border-radius: calc(0.285385 * var(--sp));
  justify-content: center;
  align-items: center;
  color: var(--white);
  background-color: var(--primary);

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color .15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn > p {
  color: var(--white);
}

.btn:hover {
  background-color: var(--primary-dark);
}

.btn.focus,
.btn:focus {
  box-shadow: 0 0 calc(0.11415 * var(--sp)) rgba(47, 202, 168, 0.5);
  outline: 0;
}
