.main-outside-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.main-inside-container {
  display: flex;
  width: 85%;
  height: 100vh;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  flex-direction: column;
  position: relative;
  justify-content: center;
}
