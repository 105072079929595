/* ---- Loading -----
created by: @lukehaas
*/

.loader, .loader:before, .loader:after {
	background: white;
	-webkit-animation: load1 1s infinite ease-in-out;
	animation: load1 1s infinite ease-in-out;
	width: 1em;
	height: 4em;
}

.loader {
	color: white;
	text-indent: -9999em;
	font-size: 11px;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

.loader:before, .loader:after {
	position: absolute;
	top: 0;
	content: '';
}

.loader:before {
	left: -1.5em;
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.loader:after {
	left: 1.5em;
}

@-webkit-keyframes load1 {
	0%, 80%, 100% {
		box-shadow: 0 0;
		height: 4em;
	}
	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
}

@keyframes load1 {
	0%, 80%, 100% {
		box-shadow: 0 0;
		height: 4em;
	}
	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
}

.loader-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	width: 100%;
	height: 5rem;
}