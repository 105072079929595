.modal {
  background-color: #000000d3;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  justify-content: center;
  align-items: center;
}
