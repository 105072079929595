.splash-logo {
  width: calc(13.2347 * var(--sp));
  height: calc(13.2347 * var(--sp));
}

.splash-anim {
  position: absolute;
  z-index: 1;
}

.image-logo {
  align-self: center;
  object-fit: contain;
  position: absolute;
  z-index: 2;
  top: 0;
}

.banner-icon {
  width: calc(26.469 * var(--sp));
}

.icons-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20%;
}

.brand-path-container {
  position: absolute;
  top: calc(13.2347 * var(--sp));
  left: calc(13.2347 * var(--sp));
}

.logo-viewbox-svg {
  min-width: 0;
  min-height: 0;
  width: calc(13.2347 * var(--sp));
  height: calc(13.2347 * var(--sp));
}
