.header48 {
  font-size: calc(3.0536195 * var(--sp));
  letter-spacing: 0;
}

.header34 {
  font-size: calc(1.90237641 * var(--sp));
  letter-spacing: calc(0.00913232 * var(--sp));
}

.header24 {
  font-size: calc(1.07019375 * var(--sp));
  letter-spacing: 0;
}

.header20 {
  font-size: calc(0.891828125 * var(--sp));
  font-weight: bold;
  letter-spacing: calc(0.00913232 * var(--sp));
}

.header30 {
  font-size: calc(1.426925 * var(--sp));
}

.subtitle14 {
  font-size: calc(0.64211625 * var(--sp));
  letter-spacing: calc(0.00913232 * var(--sp));
}

.subtitle16 {
  font-size: calc(0.7134625 * var(--sp));
  font-weight: bold;
  letter-spacing: calc(0.03595851 * var(--sp));
}

.caption12 {
  font-size: 1em;
  letter-spacing: calc(0.01426925 * var(--sp));
}

.overline10 {
  font-size: calc(0.35673125 * var(--sp));
  letter-spacing: calc(0.01769387 * var(--sp));
}

.button14 {
  font-size: calc(0.7134625 * var(--sp));
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: calc(0.04452006 * var(--sp));
}

.text-bold {
  font-weight: bold;
}

.text-regular {
  font-weight: 400;
}

.text-italic {
  font-style: italic;
}

.text-paragraph {
  margin-bottom: calc(0.35673125 * var(--sp));
}
